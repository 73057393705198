import React from "react";
import Typography from "@material-ui/core/Typography";

const Help = (props) => {
    return (
        <Typography paragraph>
            Bei Fragen zur LOA Tarifdatenbank oder der Benutzerverwaltung wenden Sie sich bitte an das Team Tarife von pharmaSuisse: <a href="mailto:tarife@pharmaSuisse.ch">tarife@pharmaSuisse.ch</a>.
        </Typography>
    );
};

export default Help