import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "antd";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";

import axios from "axios";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    logo: {
        background: "url('../logo_pharmaSuisse_wo_text.png')",
        width: 74,
        height: 80,
        marginBottom: 25,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    error: {
        color: "#da0d0d",
        fontWeight: 500,
    },
}));

const PasswordReset = (props) => {
    const classes = useStyles();
    const params = useParams();

    const token = params.token;

    const isMounted = React.useRef(false);
    const [password, setPassword] = React.useState();
    const [passwordConfirmation, setPasswordConfirmation] = React.useState();
    const [error, setError] = React.useState(null);
    const [info, setInfo] = React.useState(null);
    const [resetSuccessful, setResetSuccessful] = React.useState(false);

    useEffect(() => {
        isMounted.current = true;

        // check if the token is still valid
        axios
            .get(`/users/resetTokenStillValid/${token}`)
            .then((response) => {
                if (response.status === 200 && response.data !== true)
                    setError(
                        "Der Link ist leider nicht mehr gültig. Fordern Sie bitte einen neuen an."
                    );
            })
            .catch((data) => {
                setError("Es ist leider ein Verarbeitungsfehler aufgetreten.");
            });

        return () => (isMounted.current = false);
    }, [token]);

    const requestNewPassword = async (resetRequest) => {
        return axios
            .put("/users/passwordReset", resetRequest)
            .then((response) => {
                if (response.status === 200) {
                    setInfo(response.data);
                    setResetSuccessful(true);
                }
            })
            .catch((reason) => {
                setError(
                    reason.response
                        ? reason.response.data
                        : "Es ist ein unbekannter Verarbeitungsfehler aufgetreten"
                );
            });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        requestNewPassword({
            newPassword: password,
            resetToken: token,
        });
    };

    if (resetSuccessful)
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <div className={classes.logo} />
                    <Typography component="h1" variant="h5">
                        Neues Passwort erfolgreich gesetzt
                    </Typography>

                    <Box pt={4}>
                        <p>
                            Das neue Paswort wurde erfolgreich gesetzt. Sie
                            können sich nun mit Ihrem neuen Passwort einloggen.
                        </p>
                        <Link href="https://tarifdatenbank.swisscom.com/">Zum Login</Link>
                    </Box>
                </div>
            </Container>
        );

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <div className={classes.logo} />
                <Typography component="h1" variant="h5">
                    Neues Passwort setzen
                </Typography>
                <form className={classes.form} onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="password"
                        label="Password"
                        type="password"
                        name="password"
                        autoFocus
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="passwordConfirmation"
                        label="Passwort wiederholen"
                        type="password"
                        id="passwordConfirmation"
                        onChange={(e) =>
                            setPasswordConfirmation(e.target.value)
                        }
                    />
                    {error && (
                        <Grid container justify="center">
                            <Alert message={error} type="error" />
                        </Grid>
                    )}
                    {info && (
                        <Grid container justify="center">
                            <Alert message={info} type="info" />
                        </Grid>
                    )}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={
                            !password ||
                            password.length < 6 ||
                            password !== passwordConfirmation
                        }
                    >
                        Neues Passwort setzen
                    </Button>
                </form>
            </div>
        </Container>
    );
};

export default PasswordReset;
