import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import ListItem from "@material-ui/core/ListItem";
import HelpIcon from "@material-ui/icons/Help";
import { Link, Redirect } from "react-router-dom";
import PersonIcon from "@material-ui/icons/Person";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Route, Switch } from "react-router-dom";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Uploads from "../Uploads/Uploads";
import AuthenticationContext from "../../Contexts/AuthenticationContext";
import Help from "../Help/Help";
import Impressum from "../Impressum/Impressum";
import UserInfo from "../Users/UserInfo";
import PeopleIcon from "@material-ui/icons/People";
import Users from "../Users/Users";
import Providers from "../Providers/Providers";
import clsx from "clsx";
import Jobs from "../Jobs/Jobs";
import JobDetail from "../Jobs/JobDetail";
import LocalPharmacyIcon from "@material-ui/icons/LocalPharmacy";
import SecurityIcon from "@material-ui/icons/Security";

const drawerWidth = 360;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        backgroundColor: "#009a53",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    // necessary for content to be below app bar
    toolbar: {
        ...theme.mixins.toolbar,
        height: 160,
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        marginTop: -50,
    },
    logo: {
        backgroundImage: "url('./logo-pharmaSuisse-mit-Schriftzug.png')",
        backgroundRepeat: "no-repeat",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: 20,
        width: 290,
    },
    headerTitle: {
        color: "white",
        marginTop: 7,
        fontSize: 22,
        fontWeight: 400,
    },
}));

const Dashboard = (props) => {
    const auth = React.useContext(AuthenticationContext);
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <Grid container justify="space-between">
                        <Grid item>
                            <Typography
                                variant="h6"
                                noWrap
                                className={classes.headerTitle}
                            >
                                LOA Tarifdatenbank
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={auth.logout}>
                                <ExitToAppIcon style={{ color: "white" }} />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="left"
            >
                <div className={clsx(classes.toolbar, classes.logo)} />

                <List>
                    {(auth.role === "SUPERUSER" ||
                        auth.role === "STANDARD") && (
                        <ListItem
                            button
                            component={Link}
                            to="/uploads"
                            key="uploads"
                        >
                            <ListItemIcon>
                                <CloudUploadIcon />
                            </ListItemIcon>
                            <ListItemText primary="Uploads" />
                        </ListItem>
                    )}

                    <Divider />

                    {/* <Divider /> */}

                    {auth.role === "SUPERUSER" && (
                        <ListItem button component={Link} to="/users">
                            <ListItemIcon>
                                <PeopleIcon />
                            </ListItemIcon>
                            <ListItemText primary="Benutzerverwaltung" />
                        </ListItem>
                    )}

                    {auth.role === "ADMIN" && (
                        <ListItem button component={Link} to="/providers">
                            <ListItemIcon>
                                <LocalPharmacyIcon />
                            </ListItemIcon>
                            <ListItemText primary="Validatoren verwalten" />
                        </ListItem>
                    )}

                    <ListItem button component={Link} to="/user-info">
                        <ListItemIcon>
                            <PersonIcon />
                        </ListItemIcon>
                        <ListItemText primary="Benutzerinformation" />
                    </ListItem>
{/* 
                    {auth.role === "ADMIN" && (
                        <ListItem button component={Link} to="/job-log">
                            <ListItemIcon>
                                <FormatListBulletedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Job-Log" />
                        </ListItem>
                    )} */}

                    <Divider />

                    <Box pt={6}></Box>

                    <ListItem button component={Link} to="/help">
                        <ListItemIcon>
                            <HelpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Hilfe" />
                    </ListItem>

                    <ListItem button component={Link} to="/impressum">
                        <ListItemIcon>
                            <SecurityIcon />
                        </ListItemIcon>
                        <ListItemText primary="Impressum" />
                    </ListItem>

                    <Divider />

                    <Box pt={6}></Box>

                    <ListItem button key="logout" onClick={() => auth.logout()}>
                        <ListItemIcon>
                            <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItem>
                </List>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar} />

                <Switch>
                    <Route path="/uploads">
                        {auth.role === "ADMIN" ? (
                            <Redirect to="/" />
                        ) : (
                            <Uploads />
                        )}
                    </Route>
                    <Route path="/help">
                        <Help />
                    </Route>
                    <Route path="/impressum">
                        <Impressum />
                    </Route>
                    <Route path="/user-info">
                        <UserInfo user={auth} />
                    </Route>
                    <Route path="/users/:providerId?">
                        <Users />
                    </Route>
                    <Route path="/providers">
                        <Providers />
                    </Route>
                    <Route path="/job-log">
                        <Switch>
                            <Route path="/job-log/:jobId">
                                <JobDetail />
                            </Route>
                            <Route path="/job-log">
                                <Jobs />
                            </Route>
                        </Switch>
                    </Route>
                    <Route>
                        {auth.role === "ADMIN" ? <Providers /> : <Uploads />}
                    </Route>
                </Switch>
            </main>
        </div>
    );
};

export default Dashboard;
